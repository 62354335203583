// i18next-extract-mark-ns-start products-ringpak

import { LINKS, RINGPAK_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import ContactForm from '@components/ContactForm';
import List from '@components/List';
import PageSection from '@components/PageSection';

import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import ResourceListing from '@components/views/ResourceListing';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductRingpakPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation();

	const icon = getImage(data.icon.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content_1">
						<h2>
							Quickly and efficiently analyse ring pack performance,
							friction and wear
						</h2>
					</Trans>

					{icon && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={icon}
								alt="RINGPAK"
								className="icon-image"
							/>
						</div>
					)}
					<Trans i18nKey="content_2">
						<p>
							RINGPAK is an advanced 2D simulation package which
							enables users to predict and optimise ring-pack
							dynamics, lubrication and gas flow. A key benefit of
							RINGPAK is that, through simulation, users can optimise
							and reduce friction, wear, blow-by and oil consumption,
							which minimises the need for expensive and difficult
							testing.
						</p>
						<h2>How it works</h2>
						<p>
							RINGPAK uses advanced lubrication, gas and ring dynamic
							models to analyse ring dynamics and performance through
							simulation.&nbsp;
						</p>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{RINGPAK_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Link
						to={LINKS.ABOUT_CONTACT}
						className="button--more">
						{t("_GetInTouch")}
					</Link>
				</Aside>
			</PageSection>

			<PageSection title={t("_KeyFeatures")} layout="wide">
				<Trans i18nKey="features">
					<List type="grid">
						<li>Piston-ring dynamics</li>
						<li>
							Gas dynamics model computes the mass flow of gas past
							each ring, plus inter-ring pressures
						</li>
						<li>Hydrodynamic and boundary lubrication models</li>
						<li>Advanced oil-consumption calculations</li>
						<li>
							3D effects, including piston secondary motion from
							PISDYN and bore distortion from FEARCE
						</li>
					</List>
				</Trans>
			</PageSection>

			<ResourceListing
				resources={data.resources}
				title={t("_OurInsights")}
				layout="fullwidth"
				actions={
					<div className="text--center">
						<Button to={LINKS.INSIGHTS} type="more">
							{t("_SeeAllInsights")}
						</Button>
					</div>
				}
			/>

		</Page>
	);
};

export default ProductRingpakPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-ringpak", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/ringpak-hero.jpg" }) {
		...imageBreaker
	}
	icon: file(relativePath: { eq: "content/products/ringpak/rs_ringpak.png" }) {
		...imageBreaker
	}
	resources: allMarkdownRemark(
		filter: {
			fileAbsolutePath: { regex: "/resources/" }
			fields: { language: { eq: $language } }
			frontmatter: { products: { in: "RINGPAK" } }
		}
		sort: { fields: frontmatter___date, order: DESC }
		limit: 6
	) {
		nodes {
			fields {
				slug
			}
			frontmatter {
				title
				summary
				image {
					...imageStandard
				}
				type
			}
		}
	}
}
`;
